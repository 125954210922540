<template>
  <v-menu
    bottom
    left
    min-width="200"
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn class="ml-0" min-width="0" text v-bind="attrs" v-on="on">
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>

    <v-list :tile="false" flat nav>
      <template v-for="(p, i) in profile">
        <v-divider v-if="p.divider" :key="`divider-${i}`" class="mb-2 mt-2" />

        <!-- to="/" -->
        <app-bar-item v-else :key="`item-${i}`">
          <v-list-item-title
            v-text="p.title"
            @click="p.handler"
            style="color: white"
          />
        </app-bar-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "DefaultAccount",

  data() {
    return {
      profile: [
        {
          title: "Профиль",
          handler: () => {
            this.$router.push({ path: "/app/user/profile" }).catch(() => {});
          },
        },
        // {
        //   title: "Настройки",
        //   handler: () => {
        //     // console.log("app-bar-menu - settings");
        //   },
        // },
        { divider: true },
        {
          title: "Выход",
          handler: () => {
            // console.log("app-bar-menu - logout");
            this.$store.dispatch("auth/logout");
          },
        },
      ],
    };
  },
};
</script>
